import {Button} from '@/components/button';
import {HSeparator, VSeparator} from '@/components/separator';
import {Tag} from '@/components/tag';
import {
    ArrowLeftEndOnRectangleIcon,
    CalendarDaysIcon,
    CheckBadgeIcon, ChevronUpIcon,
    ClockIcon, LinkIcon,
    MapPinIcon, ShareIcon, MinusIcon,
    TicketIcon, PlusIcon
} from '@heroicons/react/24/solid';
import React, {useEffect, useState} from 'react';
import {ShowEvent} from "@/services/events";
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import {TicketListModal} from "@/routes/guest/event/_ticket/ticketlistmodal";
import {FormatCurrency} from "@/routes/root";
import {CheckoutCart} from "@/services/checkout";
import {PaymentDetailModal} from "@/routes/guest/event/_payment/paymentdetailmodal";
import {Modal} from "@/components/modal";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import {HelmetProvider, Helmet} from 'react-helmet-async';
import {TicketSelected} from "@/routes/guest/event/_ticket/ticketselected";
import {ShowtimeSelected} from "@/routes/guest/event/_ticket/showtimeselected";

interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
    label?: React.ReactNode;
}

interface MetaTagsProps {
    type?: string;
    title: string;
    description: string;
    image: string;
}

const Section: React.FC<SectionProps> = ({label, children}) => {
    return (
        <div className='flex flex-col gap-4'>
            <h1 className='text-2xl font-semibold'>{label}</h1>
            {children}
        </div>
    );
};

export function Event() {
    const [shareModal, setShareModal] = useState(false)
    const [showTicket, setShowTicket] = useState(false)
    const [showSubtotal, setShowSubtotal] = useState(false)
    const [ticketQuantity, setTicketQuantity] = useState(0)
    const [ticketTotal, setTicketTotal] = useState(0)
    const [ticketSubtotal, setTicketSubtotal] = useState(0)
    const [ticketAdminFee, setTicketAdminFee] = useState(0)
    const {slug} = useParams();
    const dispatch: AppDispatch = useDispatch();
    const {event} = useSelector((state: any) => state.event);
    const {selectedTicket} = useSelector((state: any) => state.tickets);
    const {loading, cart} = useSelector((state: any) => state.checkout);
    const navigate = useNavigate();

    const checkout = () => {
        let seat = {};
        if (event.type === "Cinema") {
            seat = {
                seats: selectedTicket?.reserved_seat
            };
        }

        if (selectedTicket.id) {
            dispatch(CheckoutCart(event.id, event.type, selectedTicket.id, ticketQuantity, seat));
        }
    }

    const isLoggedIn = localStorage.getItem("user") ?? null;

    useEffect(() => {
        if (cart.id) {
            navigate('checkout')
        }
    }, [cart]);

    useEffect(() => {
        dispatch(ShowEvent(slug))
    }, [dispatch, slug]);

    useEffect(() => {
        if (selectedTicket.id && event.type !== "Cinema") {
            setTicketQuantity(1);
        }

        if (selectedTicket.id) {
            setShowTicket(false)
        }
    }, [selectedTicket]);

    useEffect(() => {
        let selectedTicketPrice = selectedTicket.price ?? 0;
        let ticketAdminFee = ticketQuantity * 5000;
        let ticketSubtotal = selectedTicketPrice * ticketQuantity;
        let ticketTotal = (selectedTicketPrice * ticketQuantity) + ticketAdminFee;

        setTicketSubtotal(ticketSubtotal)
        setTicketAdminFee(ticketAdminFee)
        setTicketTotal(ticketTotal)
    }, [selectedTicket, ticketQuantity]);

    const DynamicMetaTags: React.FC<MetaTagsProps> = ({type = 'website', title, description, image}) => (
        <Helmet>
            <meta property="og:type" content={type}/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:image" content={image}/>
            <title>{title}</title>
            <meta property="description" content={description}/>
        </Helmet>
    );

    const metaData = {
        title: event.title ? event.title + ' | Tokoevent' : 'Tokoevent',
        description: event.description,
        image: event.banner.fallback_url
    };

    return (
        <HelmetProvider>
            <DynamicMetaTags
                type={'website'}
                title={metaData.title}
                description={metaData.description}
                image={metaData.image}
            />
            <>
                <Modal
                    show={shareModal}
                    onRequestClose={() => setShareModal(false)}
                    width='small'
                    title='Bagikan event'
                    body={
                        <>
                            Klik tombol di bawah
                            untuk membagikan event ini atau untuk menyalin link!
                        </>
                    }
                    footer={
                        <div className='flex justify-end gap-3'>
                            <Button
                                onClick={async () => {
                                    if (navigator.share) {
                                        try {
                                            await navigator.share({
                                                url: window.location.href,
                                            });
                                        } catch (error) {
                                        }
                                    }
                                }}
                                variant='primary'
                                leftIcon={<LinkIcon/>}
                            >
                                Bagikan
                            </Button>
                        </div>
                    }
                />

                <PaymentDetailModal
                    paymentFee={0}
                    showSubtotal={showSubtotal}
                    setShowSubtotal={setShowSubtotal}
                    ticket={selectedTicket}
                    ticketTotal={ticketTotal}
                    subtotal={ticketSubtotal}
                    adminFee={ticketAdminFee}
                    quantity={ticketQuantity}
                />

                <TicketListModal show={showTicket} onRequestClose={() => {
                    setShowTicket(!showTicket)
                }} tickets={event.tickets}/>
                <div className='grid lg:grid-cols-5 gap-6'>
                    <div className={'lg:col-span-3'}>
                        <img
                            className='h-full w-full rounded-md object-fill'
                            src={event.banner?.fallback_url || '/tmp/1200x900.png'}
                            srcSet={event.banner?.srcset}
                            alt={event.title}
                        />
                    </div>
                    <div className='lg:col-span-2 shrink-0 basis-[429px] flex-col gap-5'>
                        <div className={'rounded-md border-[1px] border-neutral-40 p-5'}>
                            <div className='flex justify-between gap-2'>
                                <div className='flex items-start gap-2'>
                                    {event.location && <Tag color='danger'>{event.location?.type}</Tag>}
                                    <Tag color='neutral'>{event.type}</Tag>
                                    <Tag color={event?.status?.color}>{event?.status?.label}</Tag>
                                </div>
                                <ShareIcon className={'h-[18px] w-[18px] text-gray-400 my-auto'} onClick={() => {
                                    setShareModal(true)
                                }}/>
                            </div>
                            <h1 className='max-line-1 sm:max-line-2 my-4 flex-shrink-0 overflow-hidden text-base font-medium sm:text-xl sm:font-semibold'>
                                {event.title}
                            </h1>
                            <HSeparator/>
                            <div className='flex flex-col gap-3'>
                                <div className='flex items-center mt-2'>
                                    <p className='mr-auto text-sm font-medium'>Pilih tiket</p>
                                </div>

                                <div className='flex gap-3 rounded-md border-[1px] border-neutral-40 p-3 cursor-pointer'
                                     onClick={() => {
                                         setShowTicket(true)
                                     }}>
                                    <TicketIcon className='h-[20px] w-[20px] text-primary'/>
                                    <p className='text-sm font-medium'>{selectedTicket.name ?? 'Lihat tiket'}</p>
                                </div>

                                {event.type !== "Cinema" && <TicketSelected selectedTicket={selectedTicket}
                                                                            setTicketQuantity={setTicketQuantity}
                                                                            ticketQuantity={ticketQuantity}/>}

                                {event.type === "Cinema" && <ShowtimeSelected selectedTicket={selectedTicket}
                                                                              setTicketQuantity={setTicketQuantity}
                                                                              ticketQuantity={ticketQuantity}/>}

                                {selectedTicket.name && <HSeparator className={'hidden md:block'}/>}
                                {/*<div className='flex rounded-md border-[1px] border-primary'>
              <div className='bg-primary p-3'>
                <TicketIcon className='h-[20px] w-[20px]' />
              </div>
              <div className='flex w-full p-3'>
                <p className='mr-auto text-sm font-medium'>
                  Daftar voucher tersedia
                </p>
                <ChevronRightIcon className='h-[20px] w-[20px] self-center' />
              </div>
            </div>*/}
                                {selectedTicket.name && <div
                                    className={'absolute md:shadow-none shadow-2xl shadow-gray-400 flex md:flex-col justify-between left-0 right-0 bottom-0 z-[5] p-4 bg-white md:static md:p-0'}>
                                    <div className='md:flex gap-2'>
                                        <p className='mr-auto text-base font-medium'>Subtotal</p>
                                        <div className={'flex'}>
                                            <p className='text-base font-medium text-danger'><FormatCurrency
                                                amount={ticketTotal}/></p>
                                            <ChevronUpIcon className='ml-2 h-[16px] w-[16px] self-center'
                                                           onClick={() => {
                                                               setShowSubtotal(true)
                                                           }}/>
                                        </div>
                                    </div>
                                    {event?.status?.label === 'Aktif' &&
                                        <Button loading={loading} className={'md:w-full mt-2'}
                                                leftIcon={<CheckBadgeIcon/>}
                                                onClick={checkout}>
                                            Beli tiket
                                        </Button>}
                                </div>}
                                {event?.status?.label === 'Tidak Aktif' &&
                                    <div
                                        className={'absolute md:shadow-none shadow-2xl shadow-gray-400 flex md:flex-col justify-between left-0 right-0 bottom-0 z-[5] p-4 bg-white md:static md:p-0'}>
                                        <Button loading={loading} variant={'danger'} className={'w-full mt-2'}
                                                leftIcon={<ExclamationTriangleIcon/>}>
                                            Event sedang tidak aktif
                                        </Button>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <HSeparator/>
                <Section label='Penyelenggara'>
                    <div className='flex items-center gap-2'>
                        <img src={event.organizer?.logo?.fallback_url} srcSet={event.organizer?.logo?.srcset}
                             className={"w-12 h-12 rounded-full object-cover"} alt="Logo Organizer"/>
                        <span className='shrink-0 text-lg font-normal'>
            {event.organizer?.name}
          </span>
                    </div>
                </Section>
                <HSeparator/>
                <Section label='Detail Acara'>
                    <div className='flex flex-col gap-3'>
                        <div className='flex items-center gap-2'>
                            <MapPinIcon className='w-[20px] shrink-0 grow-0 text-neutral-60 mb-auto mt-1.5'/>
                            <div>
                            <span className='shrink-0 text-lg font-normal'>
                              {event.location?.name}
                            </span>
                                {event.location?.url &&
                                    <p className='shrink-0 text-sm font-normal underline text-blue-500 cursor-pointer'
                                       onClick={() => {
                                           window.open(event.location?.url, '_blank')
                                       }}>
                                        Buka di Google Maps
                                    </p>}
                            </div>
                        </div>
                        <div className='flex flex-wrap gap-2'>
                            <div className='flex items-center gap-2'>
                                <CalendarDaysIcon className='w-[20px] text-neutral-60'/>
                                <span className='text-lg font-normal'>{moment(event.start_at).format('MMM D')}</span>
                            </div>
                            -
                            <div className='flex items-center gap-2'>
                            <span
                                className='text-lg font-normal'>{moment(event.end_at).format('MMM D, YYYY')}</span>
                            </div>
                        </div>
                    </div>
                </Section>
                <Section label='Deskripsi'>
                    <div className='text-base font-normal text-neutral-70'>
                        <p className={'whitespace-pre-wrap'}>
                            {event.description}
                        </p>
                    </div>
                </Section>
                <Section label='Syarat & Ketentuan'>
                    <div
                        className={`text-base font-normal text-neutral-70 ${selectedTicket.name ? 'mb-24' : 'mb-0'} lg:mb-0`}>
                        <p className={'whitespace-pre-wrap'}>{event.terms}</p>
                    </div>
                </Section>
            </>
        </HelmetProvider>);
}
