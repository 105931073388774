import {Button} from "@/components/button";
import React, {useState} from "react";
import {SelectTicket} from "@/reducers/tickets";
import {AppDispatch} from "@/root/store";
import {useDispatch} from "react-redux";

interface TicketSelectedProps {
    selectedTicket: any;
    ticketQuantity: number;
    setTicketQuantity: (quantity: number) => void;
}

export const ShowtimeSelected: React.FC<TicketSelectedProps> = ({
                                                                    selectedTicket,
                                                                    ticketQuantity,
                                                                    setTicketQuantity
                                                                }) => {
    const dispatch: AppDispatch = useDispatch();

    const toggleBlockSeat = (rowIndex: number, colIndex: number) => {
        const newLayout = selectedTicket.seat_layout?.map((row: any, rIdx: number) =>
            row?.map((seat: any, cIdx: number) =>
                rIdx === rowIndex && cIdx === colIndex
                    ? {...seat, on_reserved: !seat.on_reserved}
                    : seat
            )
        );

        const reservedSeats: { number?: string }[] = [];

        const updatedQuantity = newLayout?.reduce((total: number, row: any, rIdx: number) => {
            return total + row.filter((seat: any, cIdx: number) => {
                if (seat.on_reserved) {
                    reservedSeats.push({
                        number: seat.id
                    });
                }
                return seat.on_reserved;
            }).length;
        }, 0);

        setTicketQuantity(updatedQuantity);
        dispatch(SelectTicket({...selectedTicket, seat_layout: newLayout, reserved_seat: reservedSeats}));
    };

    return (
        <>
            {selectedTicket.id && <>
                <p className='text-sm font-medium'>Pilih kursi</p>
                <div className={'flex justify-center'}>
                    <div className="w-[320px] overflow-x-auto">
                        <div className="grid gap-2">
                            {selectedTicket?.seat_layout?.map((row: any, rowIndex: number) => (
                                <div key={rowIndex} className="flex gap-2 justify-start">
                                    {row?.map((seat: any, colIndex: number) => (
                                        <Button
                                            key={seat.id}
                                            onClick={(e: any) => {
                                                if (!seat.reserved && selectedTicket.max_buy > ticketQuantity) {
                                                    e.preventDefault();
                                                    toggleBlockSeat(rowIndex, colIndex)
                                                }
                                            }}
                                            className={`w-8 h-8 px-2 py-2 border ${
                                                seat.blocked || seat.reserved
                                                    ? 'bg-gray-500 text-white'
                                                    : seat?.on_reserved
                                                        ? 'bg-green-500 text-white'
                                                        : 'bg-gray-200'
                                            }`}
                                            title={
                                                seat.blocked || seat.reserved
                                                    ? 'Blocked'
                                                    : seat?.on_reserved
                                                        ? 'Reserved'
                                                        : 'Available'
                                            }
                                        >
                                            {seat.id}
                                        </Button>
                                    ))}
                                </div>
                            ))}
                            <div className="mt-12 text-center">
                                Screen
                                <div className="h-4 bg-blue-400"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </>}
        </>
    )
}
